import { USERS, CUSTOMERS, DASHBOARDHOME, REQUESTS } from "../ROUTECONST";

export const headerMenuData = [
  {
    text: "Home",
    isActive: true,
    link: DASHBOARDHOME,
  },
  {
    text: "User List",
    isActive: false,
    link: USERS,
  },
  {
    text: "Content Management",
    isActive: false,
    link: CUSTOMERS,
  },
  {
    text: "Referral System",
    isActive: false,
    link: REQUESTS,
  },
];
