import media from 'GlobalStyles/utils/media'
import styled from 'styled-components/macro'

export const SingleMemberDiv = styled.div`
    padding: 10px;
`

export const BackArea = styled.div`
    display: flex;
    p{
        font-family: Nunito;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: #274B89;
        margin-left: 15px;
        cursor: pointer;
    }
`
export const MemberBoxDiv = styled.div`
    display: flex;

`
export const MemberBox = styled.div`
    display: flex;
    flex-direction:column;
    /* width: 398px;
    max-width: 398px; */
    width: 100%;
    height: 100%;
    /* min-height: 500px; */
    margin-top: 30px;
    overflow-y: auto;

    small{
        font-family: Nunito;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 27px;
        color: #274B89;
    }
`

export const MProfile = styled.div`
    display: flex;
    justify-content: space-around;
    margin-top: 10px;

    h4{
        font-family: Nunito;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 158.8%;
        color: rgba(59, 59, 59, 0.7);
    }

    h6{
        font-family: Nunito;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color: #274B89;
    }


    ${media.tablet`
        flex-direction: column;
    `}
`
export const MProfileDetails = styled.div`
    /* height: 100%;
    width: 100%; */
    width: 398px;
    max-width: 398px;
    height: 100%;
    overflow:auto;

    ${media.tablet`
        width: 100%;
        height: auto;

    }
    `}
`

export const MemPicture = styled.img`
        height: 58.66px;
        width: 50px;

        ${media.tablet`
            height: 58.66px;
            width: 50px;
    `}

`



export const MemberMenu = styled.div`
    display: flex;
    width: 398px;
    border-bottom: 1px solid #C2E0FF;
     margin-top:${({mt}) => mt || null};
     margin-bottom:${({mb}) => mb || null}; 
    .menu-active{
            border-bottom: 2px solid #274B89;
        }

    p{
        font-family: Poppins;
        font-style: normal;
        font-weight: 600;
        font-size: 11.0995px;
        line-height: 17px;
        color: #274B89;
        margin-right:15px;
        padding-bottom: 7px;
        cursor: pointer;

    }

    ${media.tablet`
        width: 100%;
        /* overflow: auto; */
    `}
    ${media.mobile`
        width: 100%;
        /* overflow: auto; */
    `}
    ${media.smallMobile`
        width: 100%;
        /* overflow: auto; */
    `}
`

export const NameArea = styled.div`
    
    h2{
        font-family: Nunito;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        color: #274B89;
    }

    small{
        font-family: Nunito;
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        color: #929AA7;
    }
`

export const MemDiv = styled.div`
    border-top: 1px solid #e7e7e7;
    padding: '15px 0';
    margin-top: 10px;
    display: flex;
    flex-direction: column;

    img{
        width: 78px;
        height: 78px;
        /* height: 24px; */
        margin-top: -10px;
    }
    small{
        font-family: Nunito;
        font-style: normal;
        font-weight: normal;
        font-size: 12px;
        line-height: 19px;
        color: rgba(59, 59, 59, 0.7);
    }

    p{
        font-family: Nunito;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 27px;
        color: #274B89;
    }
`

export const BarchartArea = styled.div`
    height: 300px; 
    width: 100%;
    overflow: auto;
    margin-left: -30px;
`

export const BarChartImage = styled.img`
    width: 300px;
    height: 150px;
`

export const MetricsArea = styled.div`
    h4{
        font-family: Nunito;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 158.8%;
        color: rgba(59, 59, 59, 0.7);
    }

    h6{
        font-family: Nunito;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 27px;
        color: #274B89;
    }

`

export const StatsArea = styled.div`
    width: 160px;
`

export const StatDiv = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    text-align: center;
`