import React, { useState } from 'react'
import DummyPic from 'assets/svg/dummyPic.svg'
import InputField from 'components/onboading/inputField'
import { CompanyDetailsContainer, CompanyForm, UploadImageDiv } from './style'
import { UPLOADPICTURE } from 'routes/backend'
import { useCorporateUser } from 'context/userDetails'


export default function CompanyDetails() {

    const [fileSrc, setFileSrc] = useState()
    const [fileName, setFileName] = useState()
    const {corporateUser, setCorporateUser} = useCorporateUser()

    function handleFormChange(e){
        setCorporateUser({
            ...corporateUser,
            data:{
                ...corporateUser.data,
                [e.target.name]: e.target.value
            }
        })
    }
    
    async function handleFile(e){  
 
        const file = await URL.createObjectURL(e.target.files[0]);
        const myFile = await e.target.files[0]
        setFileSrc(file)
        setFileName(myFile.name)
        console.log(myFile)
    
     
    
      // Update the formData object
      let formdata = new FormData();
      formdata.append("file", myFile, file);
      
      let requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
      };
      
      fetch(UPLOADPICTURE, requestOptions)
        .then(response => response.json())
        .then(res => {
            console.log(res)
            if(res.status === true){
                setCorporateUser({
                    ...corporateUser,
                    data:{
                        ...corporateUser.data,
                        profileUrl: res.data.url
                    }
                })
            }
        })
        .catch(error => console.log('error', error))
    }
    return (
        <CompanyDetailsContainer>
            {console.log('otherBrand',corporateUser)}
            <UploadImageDiv>
                <img src={corporateUser.data.profileUrl || fileSrc || DummyPic} alt='proPic' />
                <p>Upload new photo</p>
                <p>{fileName}</p>
                <input onChange={handleFile} type='file' />
            </UploadImageDiv>
            <CompanyForm>
                <p style={{color:'black'}}>company details</p>
                <InputField handleFormChange={handleFormChange} value={corporateUser.data.brandName || ''} type='text' displayEye='false' name='brandName' placeholder='Brand Name' />
                <InputField handleFormChange={handleFormChange} value={corporateUser.data.contactEmail || ''} type='text' displayEye='false' name='contactEmail' placeholder='Contact Mail' />
                <InputField handleFormChange={handleFormChange} value={corporateUser.data.contactPhoneNumber || ''} type='text' displayEye='false' name='contactPhoneNumber' placeholder='Contact Phone Number' />
                <InputField handleFormChange={handleFormChange} value={corporateUser.data.username || ''} type='text' displayEye='false' name='username' placeholder='Choose a username' />
            </CompanyForm>
        </CompanyDetailsContainer>
    )
}
