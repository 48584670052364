// import GoogleMaps from 'components/dashboard/main/GoogleMap'
// import Map from 'components/dashboard/main/GoogleMap/map'
import LeftLayout from 'components/onboading/leftLayout'
import React from 'react'
import Form from './form'
import {LoginPageContainer} from './style'
// import { geolocated } from "react-geolocated";
// import { Geolocation } from 'components/dashboard/main/GoogleMap/geolocation';

export default function Login() {

    return (
        <LoginPageContainer>
            <LeftLayout buttonText = 'Sign Up' />
            <Form />
            {/* <Geolocation /> */}
        </LoginPageContainer>
    )
}
