import BestRating from 'components/dashboard/main/bestRating'
import { bestRatingData } from 'components/dashboard/main/bestRating/bestRatingData'
import DataDetails from 'components/dashboard/main/DataDetails/DataDetails'
import { detailsData } from 'components/dashboard/main/DataDetails/detailsData'
import Graph from 'components/dashboard/main/graph'
import Performance from 'components/dashboard/main/performance'
import BestPerformance from 'components/dashboard/main/performance/bestPerformance'
import { bestPerformanceData, performanceData, topSearchData } from 'components/dashboard/main/performance/performanceData'
import Tag from 'components/dashboard/main/performance/tag'
import TopSellers from 'components/dashboard/main/topSellers'
import { topSellersData } from 'components/dashboard/main/topSellers/mockData'
import { TopSellersContainer } from 'components/dashboard/main/topSellers/style'
import React from 'react'
import { 
    BestPerformances,
    BlueBox, 
    BottomLayout, 
    DataDetailsDiv, 
    FirstLayout, 
    GraphArea, 
    GraphHeader, 
    GreenBox, 
    HomeContainer, 
    MapArea, 
    PerformanceDiv,
    UserPerformanceDetails,
    TopSearches,
    SearchTags,
    TopicTitle,
    TopSellersDiv,
    BestRatings
} from './style'
// import Modal from 'components/UI/Modal/index'
import TitleHead from './titleHead'




export default function DashboardHome() {

    // const {user, setUser} = useAuthContext()

    // useEffect(()=>{
    //     const {token} = user
    //     axios.get(GETPROFILEDATA,{
    //         headers:{
    //             Authorization: `bearer ${token}`
    //         }
    //     })
    //     .then(res=>{
    //         const {data} = res.data
    //         setUser((prev)=>{
    //                 return{
    //                 ...prev,
    //                 data:{
    //                     ...prev.data,
    //                     ...data
    //                 }
    //         }})
    //     })
    //     .catch(err=>console.log(err))
    // })
    return (
        <HomeContainer>
            <UserPerformanceDetails>
            <FirstLayout>
                <DataDetailsDiv>
                    {
                        detailsData.map((data,i)=>(
                            <DataDetails key={i}
                                         icon={data.icon}
                                         title={data.title}
                                         total={data.total}
                                         updateText={data.updateText}
                                         updateIcon={data.updateIcon}
                                         isIncreasing={data.isIncreasing}
                            />
                        ))
                    }
                </DataDetailsDiv>
                <PerformanceDiv>
                    {
                        performanceData.map((data,i)=>(
                            <Performance
                                key={i}
                                percentage={data.percentage}
                                title={data.title}
                                adsRunning={data.adsRunning}
                            />
                        ))
                    }
                </PerformanceDiv>
            </FirstLayout>
            <MapArea>
            <iframe title='map' width="471" height="248" id="gmap_canvas" src="https://maps.google.com/maps?q=spottr&t=k&z=13&ie=UTF8&iwloc=&output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
            </MapArea>
            </UserPerformanceDetails>
            <BottomLayout>
                <GraphArea>
                    <GraphHeader>
                        <p>Transaction activity</p>
                        <div>
                            <BlueBox />
                            <small>Total Paid</small>
                        </div>
                        <div>
                            <GreenBox />
                            <small>Total Spent</small>
                        </div>
                    </GraphHeader>
                    <Graph /> 
                </GraphArea>
                <BestPerformances>
                    <TitleHead />
                    {
                        bestPerformanceData.map((item,i)=>(
                            <BestPerformance key={i} />
                        ))
                    }                   
                </BestPerformances>
                <TopSearches>
                    <TitleHead title='Top Searches' />
                    <SearchTags>
                        {
                            topSearchData.map((tag,i)=>(
                                <Tag key={i} icon={tag.icon} tag={tag.title} />
                            ))
                        }
                    </SearchTags>
                    <TopSellersDiv>
                        <TopicTitle>Top Sellers</TopicTitle>
                        <TopSellersContainer>
                            {
                                topSellersData.map((seller,i)=>(
                                    <TopSellers 
                                        key={i}
                                        image={seller.image} 
                                        bg={seller.bg} 
                                        name={seller.name} 
                                        location={seller.location} 
                                        total={seller.total} 
                                    /> 
                                ))
                            }   
                        </TopSellersContainer>
                    </TopSellersDiv>                     
                    <TopSellersDiv>
                        <TopicTitle>Best Rating</TopicTitle>
                        <BestRatings>
                            {
                                bestRatingData.map((seller,i)=>(
                                    <BestRating 
                                        key={i}
                                        image={seller.image} 
                                        bg={seller.bg} 
                                        name={seller.name} 
                                        location={seller.location} 
                                        total={seller.total} 
                                        rating={seller.rating}
                                    /> 
                                ))
                            }   
                        </BestRatings>
                    </TopSellersDiv>                     
                </TopSearches>
            </BottomLayout>
        </HomeContainer>
    )
}
