import styled from 'styled-components/macro'
import {Link} from 'react-router-dom'

export const SpottrLink = styled(Link)`
   text-decoration: none;
   text-align:center;
   color: ${(color)=>color?color:null}
`
export const Div = styled.div`
  width: ${({ width }) => (width ? width : null)};
  height: ${({ height }) => (height ? height : null)};
  margin: ${({ margin }) => (margin ? margin : null)};
  padding: ${({ padding }) => (padding ? padding : null)};
  display: ${({ display }) => display};
  flex-direction: ${({ flexDirection }) => flexDirection || null};
  flex-wrap: ${({ wrap }) => wrap};
  align-items: ${({ alignI }) => alignI};
  align-self: ${({ align }) => (align ? align : null)};
  justify-content: ${({ justify }) => (justify ? justify : null)};
  background-color: ${({ backgroundColor }) => backgroundColor ? backgroundColor : null};
  background-image: ${({ image }) => (image ? `url(${image})` : null)};
  box-shadow: ${({ boxShadow }) => (boxShadow ? boxShadow : null)};
  box-sizing: ${({ boxSizing }) => (boxSizing ? boxSizing : null)};
  background-size: cover;
  background-repeat: no-repeat;
  border: ${({ border }) => (border ? border : null)};
  border-radius: ${({ br }) => (br ? br : null)};
`;

export const Img = styled.img`
   width: ${({w})=>w?w:null};
   height: ${({h})=>h?h:null};
   margin: ${({m})=>m?m:null};
   padding: ${({p})=>p?p:null};
   border-radius: ${({br})=>br?br:null};
   align-self: ${({align})=>align? align: null};
`

export const Button = styled.button`
  background-color: ${({ bc }) =>
  bc ? bc : null};
  width: ${({ w }) => (w ? w : null)};
  padding: ${({ p }) => (p ? p : null)};
  border: ${({ border }) => (border ? `1px solid ${border}` : `none`)};
  border-radius: ${({ br }) => (br ? br : null)};;
  outline: none;
  font-weight: ${({ fw }) => (fw ? fw : null)};;
  cursor: pointer;
  color: ${({ color }) => (color ? color : null)};
  margin: ${({ m }) => (m ? m : null)};
  font-weight: ${({ fw }) => (fw ? fw : null)};
`;


export const SpottrInput = styled.input`
  font-weight: ${({ fw }) => (fw ? fw : null)};;
  color: ${({ color }) => (color ? color : null)};
  background: ${({ bg }) => (bg ? bg : null)};
  display: ${({ display }) => display};
  margin: ${({ m }) => (m ? m : null)};
  outline: ${({ o }) => (o ? o : null)};
  border: ${({ border }) => (border ? `1px solid ${border}` : `none`)};
  padding: ${({ p }) => (p ? p : null)};
`

export const SpottrP = styled.p`
  font-weight: ${({ fw }) => (fw ? fw : null)};;
  color: ${({ color }) => (color ? color : null)};
  background: ${({ bg }) => (bg ? bg : null)};
  display: ${({ display }) => display};
  margin: ${({ m }) => (m ? m : null)};
  margin-left: ${({ ml }) => (ml ? ml : null)};
  margin-right: ${({ mr }) => (mr ? mr : null)};
  margin-top: ${({ mt }) => (mt ? mt : null)};
  margin-bottom: ${({ mb }) => (mb ? mb : null)};
  text-transform:${({ tr }) => (tr ? tr : null)};
  font-size:${({ fs }) => (fs ? fs : null)};
  padding: ${({ p }) => (p ? p : null)};
`;
export const SpottrRatingDiv = styled.div`
width: 50px;
  height: 20px;
  background-color: #274B89;
  border-radius: 5px;
  position: relative;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 2px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 10px;
  color: #313333;
  text-align: center;
  &:after {
  content: " ";
  width: 0px;
  height: 0px;
  display: flex;
  border-top: 10px solid #274B89;
  border-left: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid transparent;
  position: absolute;
  left: 50%;
  top: 100%;
  margin-left: -10px;
}

  p {
    margin-top: 5px;
    margin-left: 4px;
  }
`;

export const RatingContent = styled.div`
    display: flex;
    justify-content:center;
    align-items: center;
    color:#fff;
    img{
        margin-top: -15px;
    }
`;