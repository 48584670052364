import React from "react";
import { Div, Img, SpottrP } from "GlobalStyles/spotrrStyles/style";
import PerformanceHead from "./head";
import star from "assets/svg/startArrange.svg";
import BestSellers from "./sellers";
import { PerformanceItemsContainer } from "./style";
function PerfomancePage() {
  return (
    <Div width="100%">
      <PerformanceHead />
      <Div display="flex" justify="flex-end">
        <Img src={star} alt="Star" />
        <SpottrP fs="12px" fw="800" ml="10px">
          Arrange Tray
        </SpottrP>
      </Div>
      {/* <Div style={{display: "flex",overflow:'scroll',width:'100%'}}>
        <BestSellers />
        <BestSellers />
        <BestSellers />
        <BestSellers />
      </Div> */}
      <PerformanceItemsContainer>
        <BestSellers />
        <BestSellers />
        {/* <BestSellers />
        <BestSellers />
        <BestSellers /> */}
      </PerformanceItemsContainer>
    </Div>
  );
}

export default PerfomancePage;
