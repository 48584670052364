import React from "react";
import { useHistory } from "react-router-dom";
import star from "assets/mem/star.png";
import {
  Rate,
  Stats,
  Verified,
  TableRowDiv,
  TableDropdownMenu,
  TableDropdownMenuLabel,
} from "./style";
import Colors from "GlobalStyles/appColors";
import Divider from "GlobalStyles/dividerLine";
// import { changePillColor, logData } from "utility/methods";
// import Pill from "components/UI/Pill";

export const Row = ({ data, index, id }) => {
  // const [showOptions, setShowOptions] = useState(false);

  // const toggleOptions = (e) => {
  //   e.stopPropagation();
  //   setShowOptions(!showOptions);
  // };

  const history = useHistory();

  const routeChange = () => {
    let path = `/admin/member/${id}`;
    history.push(path);
  };

  const tableRow = {
    backgroundColor: "#F6F6F6",
    cursor: "pointer",
  };

  return (
    <TableRowDiv status={data.status} style={tableRow} onClick={routeChange}>
      <td>{index + 1}</td>
      <td>
        <img src={data.photo} alt="ikon" />
      </td>
      <td>{data.name}</td>
      <td>{data.email}</td>
      <td>{data.username}</td>
      <td>{data.account}</td>
      <td>{data.bank}</td>
      <td>{data.amount}</td>
      <Rate>
        <img src={star} alt="icon" />
        {data.rating}
      </Rate>
      <Verified>{data.verificaiton}</Verified>
      <Stats status={data.status}>{data.status}</Stats>
      <td>{data.date}</td>
      <td>...</td>
      <TableDropdownMenu>
        <TableDropdownMenuLabel>send message</TableDropdownMenuLabel>
        <Divider />
        <TableDropdownMenuLabel>verify user</TableDropdownMenuLabel>
        <Divider />
        <TableDropdownMenuLabel>90 days</TableDropdownMenuLabel>
        <Divider />
        <TableDropdownMenuLabel color={Colors.danger} weight="bold">
          suspend user
        </TableDropdownMenuLabel>
        <Divider />
      </TableDropdownMenu>
    </TableRowDiv>
    // </UserLink>
  );
};

export default Row;
