import { useCorporateUser } from 'context/userDetails';
import React from 'react';
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import { SpottRPlaces } from './style';
 

function SpottrPlaces(){
    // const [address, setAddress] = useState('')
    // const [location, setLocation] = useState({})
    const {corporateUser, setCorporateUser} = useCorporateUser()
    const handleChange = address => {
        // setAddress( address );
        // setCorporateUser((prev)=>{
        //     return{
        //     ...prev,
        //     address
        // }})
        setCorporateUser({
            ...corporateUser,
            address
        })
        // console.log('adressChangr',address)
    };
 
  const handleSelect = myAddress => {
    geocodeByAddress(myAddress)
      .then(results => {
        //   setAddress(results[0].formatted_address)
          setCorporateUser((prev)=>{
              return{
              ...prev,
              address:results[0].formatted_address
          }})
          return getLatLng(results[0])
        })
      .then(latLng => {
        //   setLocation({
        //       lat: latLng.lat,
        //       lng: latLng.lng
        //   })
          setCorporateUser((prev)=>{
          return{
              ...prev,
              data:{
                  ...prev.data,
                  lat: latLng.lat,
                  lng: latLng.lng

              }
          }})
        //   console.log('Success', latLng)
        //   console.log('effect',corporateUser)
        })
      .catch(error => console.error('Error', error));
  };

//   useEffect(()=>{
    
//     console.log('location',location)
//     console.log('address',address)
//     console.log('mainCoop',corporateUser)
//   },[address,location,setCorporateUser])

    return (
      <PlacesAutocomplete
        value={corporateUser.address}
        onChange={handleChange}
        onSelect={handleSelect}
      >
          
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <SpottRPlaces
              {...getInputProps({
                placeholder: 'Enter Location',
                className: 'location-search-input',
              })}
            />
            <div className="autocomplete-dropdown-container">
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                //   console.log(suggestion)
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item';
                const style = suggestion.active
                  ? { backgroundColor: '#274B89', cursor: 'pointer',color:'white', backgroundRadius:'3px' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' };
                return (
                  <div
                    key={suggestion.placeId}
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
// }

export default SpottrPlaces;