import styled from "styled-components/macro";

export const AdminBackdropContainer = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  justify-content:center;
  align-items: center;
  /* z-index: 100; */
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;
