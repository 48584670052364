import React from "react";
import { AdminBackdropContainer } from "./style";

function AdminBackdrop({ children, showAdminModal, handleClickAdminModal }) {
  return (
    <div>
      {showAdminModal && (
        <AdminBackdropContainer onClick={handleClickAdminModal}>
          {children}
        </AdminBackdropContainer>
      )}
    </div>
  );
}

export default AdminBackdrop;
