import styled from 'styled-components/macro';


export const PerformanceItemsContainer = styled.div`
    display: flex;
    height:90vh;
    overflow-x:scroll;
    overflow-y:hidden;

`;
export const TPSellersContainer = styled.div`

   display: flex;
   justify-content:space-between;
   flex-wrap:wrap;
   width:365px;
   height:100%;
   overflow-y: scroll;

`;

