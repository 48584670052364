import React from 'react'
import { UploadImagesDiv } from './style'
import ImageIcon  from 'assets/svg/addProductImageIcon.svg'
// import { useCorporateProduct } from 'context/addProduct'

export default function UploadImages({handleFile}) {
    
    // const {corporateProduct, setCorporateProduct} = useCorporateProduct()
    // const {page} = corporateProduct
    // const [fileSrc, setFileSrc] = useState()
    // const [fileName, setFileName] = useState([])

    // async function handleFile(e){  
        
    //     const file = await URL.createObjectURL(e.target.files[0]);
    //     const myFile = await e.target.files
    //     setFileSrc(file)
    //     setFileName(myFile.name)
    //     console.log(myFile)
    //     console.log('ee',e.target.files)
    //     console.log(fileSrc)
    
     
    
    //   // Update the formData object
    //   let formdata = new FormData();
    //   formdata.append("file", myFile, file);
      
    // //   let requestOptions = {
    // //     method: 'POST',
    // //     body: formdata,
    // //     redirect: 'follow'
    // //   };
      
    // //   fetch(UPLOADMULTIPLE, requestOptions)
    // //     .then(response => response.json())
    // //     .then(res => {
    // //         console.log(res)
    // //         if(res.status === true){
    // //             setCorporateUser({
    // //                 ...corporateUser,
    // //                 data:{
    // //                     ...corporateUser.data,
    // //                     profileUrl: res.data.url
    // //                 }
    // //             })
    // //         }
    // //     })
    // //     .catch(error => console.log('error', error))
    // }

    return (
        <UploadImagesDiv>
            {/* {console.log(fileName)} */}
            <p>
                Click on the camera icon to add an image
                for your new service. You can add a maximum of 3 images.
            </p>
            <img src={ImageIcon} alt='icon' />
            <small>Upload Images</small>
            {/* {
                fileName.map((file,i)=>(
                    <small key={i}>{file.name}</small>
                ))
            } */}
            <input multiple onChange={handleFile} type='file' />
        </UploadImagesDiv>
    )
}
