import React from "react";
import { PBackAreaContent, PerformanceHeadDiv, PHeadText } from "./style";
import { ALLMEMBERS } from "../../ROUTECONST";
import { Div, SpottrLink, SpottrP } from "GlobalStyles/spotrrStyles/style";
import backArrow from "assets/svg/backArrow.svg";

export default function PerformanceHead() {
  return (
    <PerformanceHeadDiv>
      <PBackAreaContent>
        <SpottrLink to={ALLMEMBERS}>
          <Div width="120px" display="flex">
            <img src={backArrow} alt="icon" />
            <SpottrP fs="12px">Go Back</SpottrP>
          </Div>
        </SpottrLink>
        <PHeadText>Performance</PHeadText>
      </PBackAreaContent>
    </PerformanceHeadDiv>
  );
}
