export const baseUrl = 'https://thespottrapp.herokuapp.com'

//corporate 
//https://backend-27awp.ondigitalocean.app
//https://thespottrapp.herokuapp.com
export const SIGNUPUSER = `${baseUrl}/user/auth/register`
export const LOGINUSER = `${baseUrl}/user/auth/login `
export const UPLOADPICTURE = `${baseUrl}/utils/upload `
export const UPLOADMULTIPLE = `${baseUrl}/utils/upload/multiple`
export const UPDATEPROFILE = `${baseUrl}/user/account/cooperate/profile` //token
export const GETPROFILEDATA = `${baseUrl}/user/account/profile`
export const SENDOTP = `${baseUrl}/user/auth/phone_number`
export const VALIDATEOTP = `${baseUrl}/user/auth/phone_number/verify`
export const GETCATEGORIES = `${baseUrl}/utils/categories`
