import React, { useState, useEffect } from 'react'
import { DescriptionDiv, ServiceProductInput, ServiceProviderDiv, TitleText } from './style'
import { Multiselect } from 'multiselect-react-dropdown';
// import OtherTag from 'components/onboading/describeForm/otherTag'
import { GETCATEGORIES } from 'routes/backend'
import { useCorporateProduct } from '../../../context/addProduct'
// import {DescribeArea} from '../welcomePage/style'

export default function ServiceProduct() {

    const {corporateProduct} = useCorporateProduct()
    const [cartegories, setCartegories] = useState([])
    // function handleChange(e){
    //     setCorporateProduct({
    //         ...corporateProduct,
    //         product:{
    //             ...corporateProduct.product,
    //             [e.target.name]:e.target.value
    //         }
    //     })
    // }

    useEffect(()=>{
        fetch(GETCATEGORIES)
            .then(res=>res.json())
            .then(data=>{
                setCartegories([...data.data])
            })
            .catch(err=>console.log(err))
    },[])

    return (
        <ServiceProviderDiv>
            {console.log(corporateProduct)}
            <TitleText>Service/Product Name</TitleText>
            <ServiceProductInput placeholder='Type a name' />
            <small>Use something quite Straughtforward to help 
                   your users find you quickier. E.g: PlayStation 3.
            </small>
            <DescriptionDiv>
                <div>
                <TitleText style={{fontSize:'16px'}}>Description</TitleText>
                <p>0/500</p>
                </div>
                <textarea />            
            </DescriptionDiv>
            <div>
                <TitleText style={{fontSize:'16px'}}>Service/Product Cartegory</TitleText>         
            </div>
            <Multiselect
                options={cartegories}
                placeholder="Select Cartegory"
                displayValue="name"
                onSelect={(selected)=>''
             }
                id="css_custom"
                style={{ chips: { background: "white",
                                  color:'grey', 
                                  border:'1px solid #C2E0FF' }, 
                        searchBox: { border: "none", 
                                    borderRadius: "0px",
                                    background: '#ECF7FF',
                                    padding: '8px'
                                },
                        optionContainer: { // To change css for option container 
                            border: '1px solid'
                            },
                        option: { // To change css for dropdown options
                            color: 'black',
                            // background: '#ECF7FF'
                        },
                    }}
                />
            {/* <small>Make sure you are descriptive and detailed as it assure trust with users</small> */}
            {/* <DropDown data={categories} styles={{fontSize:'20px'}} /> */}
            {/* <OtherTag corporateUser = {corporateProduct} setCorporateUser = {setCorporateProduct} /> */}
        </ServiceProviderDiv>
    )
}
