import React from "react";
import { CategoryItem, CategoryText, CategoryItemImag } from "../style";

const CategoryContent = ({ image, text, textColor, backgroundColor }) => {
  return (
    <CategoryItem backgroundColor={backgroundColor}>
      <CategoryItemImag src={image} alt={text} />
      <CategoryText color={textColor}>{text}</CategoryText>
    </CategoryItem>
  );
};

export default CategoryContent;
