import media from "GlobalStyles/utils/media";
import styled from "styled-components/macro";

export const DashboardContainer = styled.div`
  background-color: #e7eef8;
  /* width: 100vw; */
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  //   justify-content: center;
  //   align-items: center;

  ${media.tablet`
        flex-direction: column;
        overflow: hidden;
        height: 100%;
    `}

    ${media.mobile`
        flex-direction: column;
        overflow: hidden;
        height: 100%;
    `}
    ${media.smallMobile`
        flex-direction: column;
        overflow: hidden;
        height: 100%;
    `}
`;

export const DashboardBody = styled.div`
  margin-left: 105px;
  width: 95%;
  display: flex;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.8);
  border: 0.2px solid #bbc4d4;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 10px;
  overflow: auto;
  height:100vh;

  ${media.tablet`
        margin-top: 50px;
        margin-left: 0;
        margin-right:0;
        width: 100%;
    `}
    ${media.mobile`
        width: 100%;
    `}
    ${media.smallMobile`
        width: 100%;
    `}
`;
