import React from "react";
import Colors from "GlobalStyles/appColors";

import userdp from "../../assets/mem/profileUser.svg";
import {
  DropdownMenuLabel,
  DropdownProfileDp,
  MenuLabelConatainer,
  ProfileDropdownContainer,
} from "./style";
import Divider from "GlobalStyles/dividerLine";

function ProfileDropdown() {
  return (
    <ProfileDropdownContainer>
      <DropdownProfileDp src={userdp} alt="User Icon" />
      <MenuLabelConatainer>
        <DropdownMenuLabel color={Colors.primary}>
          Adewale Adedamola
        </DropdownMenuLabel>
      </MenuLabelConatainer>
      <Divider />
      <MenuLabelConatainer>
        <DropdownMenuLabel color={Colors.black}>
          Create a new admin account
        </DropdownMenuLabel>
      </MenuLabelConatainer>
      <Divider />
      <MenuLabelConatainer>
        <DropdownMenuLabel color={Colors.black}>Settings</DropdownMenuLabel>
      </MenuLabelConatainer>
      <Divider />
      <MenuLabelConatainer>
        <DropdownMenuLabel color={Colors.danger}>Logout</DropdownMenuLabel>
      </MenuLabelConatainer>
    </ProfileDropdownContainer>
  );
}

export default ProfileDropdown;
