import React, {useState} from "react";

import Search from "./searchIcon";
import {
  CompanyName,
  CorporateHeader,
  SpottrLogo,
  NotificationIcon,
  HeaderContainer,
  ImageDiv,
  NotificationArea,
  NotificationDiv,
  TitleContainer,
  ProfilePicture,
  HeaderMenuArea,
  HeaderMenus,
  AddNewCategories,
  CreateTaskLinkContainer,
  MessageNotificationContainer,
  MessageCountIndicator,
  WhiteCircle,
} from "./style";
import Bell from "assets/svg/bellicon.svg";
import Message from "assets/svg/messageIcon.svg";
import DropDown from "assets/svg/down.svg";
import Logo from "assets/svg/logo.svg";
import { headerMenuData } from "../dashboardHeader/menu";
import SingleMenu from "./singleMenu";
import { SpottrLink } from "GlobalStyles/spotrrStyles/style";
import { useCorporateProduct } from "context/addProduct";
import { useAuthContext } from "context/AuthContext";
import NotificationDropDown from "components/Notification";
import ProfileDropdown from "components/profileDropDown";
import { useToggleDropdown } from "context/DropownMenuContext";

export default function DashboardHeader({ company = "Dangote Limited PLC" }) {
  const [subHeaderMenu, setSubHeaderMenu] = useState(0);
  const { corporateProduct, setCorporateProduct } = useCorporateProduct();
  const { user } = useAuthContext();
  const {
    showprofile,
    showmessages,
    shownotifications,
    setShowMessages,
    setShowprofile,
    setShowNotifications,
    toggleProfileDropdown,
    toggleMessageDropdown,
    toggleNotificationDropdown,
  } = useToggleDropdown();

  function productModal() {
    setCorporateProduct({
      ...corporateProduct,
      showProductModal: true,
    });
  }
  const toggleMessage = () => {
    toggleMessageDropdown();
    setShowprofile(false);
    setShowNotifications(false);
  };

  const toggleProfile = () => {
    toggleProfileDropdown();
    setShowMessages(false);
    setShowNotifications(false);
  };

  const toggleNotification = () => {
    toggleNotificationDropdown();
    setShowMessages(false);
    setShowprofile(false);
  };


  return (
    <HeaderContainer>
      <TitleContainer>
        <div>
          <CorporateHeader>Dashboard Overview</CorporateHeader>
          <CompanyName>Welcome Admin</CompanyName>
        </div>
        <div>
          <Search />
        </div>
        <NotificationArea>
          <NotificationDiv>
            <NotificationIcon
              src={Bell}
              alt="not"
              onClick={toggleNotification}
            />
            {shownotifications && (
              <NotificationDropDown
                headerText="Notifications"
                message="You have 5 new messages"
              />
            )}
            {showmessages && (
              <NotificationDropDown
                headerText="Messages"
                left="140px"
                message="You have 5 new messages"
              />
            )}

            <MessageNotificationContainer onClick={toggleMessage}>
              <NotificationIcon src={Message} alt="not" />
              <WhiteCircle>
                <MessageCountIndicator>
                  <p>13</p>
                </MessageCountIndicator>
              </WhiteCircle>
            </MessageNotificationContainer>
          </NotificationDiv>
          <ImageDiv onClick={toggleProfile}>
            <ProfilePicture
              src={user.data.profileUrl ? user.data.profileUrl : Logo}
            />
            <img src={DropDown} alt="drops" />
          </ImageDiv>
          {showprofile && <ProfileDropdown />}
        </NotificationArea>
      </TitleContainer>
      <HeaderMenuArea>
        <SpottrLogo src={Logo} alt="pic" />
        <HeaderMenus>
          {headerMenuData.map((menu, i) => (
            <SingleMenu key={i} text={menu.text} link={menu.link}  
            handleCLickedActive={() => setSubHeaderMenu(i)}
            Active={subHeaderMenu === i ? "menu-active": null}/>
          ))}
        </HeaderMenus>
        <CreateTaskLinkContainer>
          <SpottrLink>
            <AddNewCategories onClick={productModal}>
              + create task
            </AddNewCategories>
          </SpottrLink>
          <SpottrLink>
            <AddNewCategories onClick={productModal}>
              + add new category
            </AddNewCategories>
          </SpottrLink>
        </CreateTaskLinkContainer>
      </HeaderMenuArea>
    </HeaderContainer>
  );
}
