import React, { useState, createContext, useContext } from "react";

export const ToggleDropdwnContext = createContext();

export default function ToggleDropdownMenuContext({ children }) {
  const [showprofile, setShowprofile] = useState(false);
  const [showmessages, setShowMessages] = useState(false);
  const [shownotifications, setShowNotifications] = useState(false);

  const toggleProfileDropdown = () => setShowprofile((value) => !value);
  const toggleMessageDropdown = () => setShowMessages((value) => !value);
  const toggleNotificationDropdown = () =>
    setShowNotifications((value) => !value);

  const values = {
    showprofile,
    showmessages,
    shownotifications,
    setShowMessages,
    setShowprofile,
    setShowNotifications,
    toggleProfileDropdown,
    toggleMessageDropdown,
    toggleNotificationDropdown,
  };
  return (
    <ToggleDropdwnContext.Provider value={values}>
      {children}
    </ToggleDropdwnContext.Provider>
  );
}

export const useToggleDropdown = () => useContext(ToggleDropdwnContext);
