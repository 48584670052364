import React from 'react'
import FirstImage from 'assets/svg/firstImage.svg'
import { ShowUploadsDiv } from './style'

export default function ShowUploads() {
    return (
        <ShowUploadsDiv>
            <img src={FirstImage} alt='upload' />
            <img src={FirstImage} alt='upload' />
            <img src={FirstImage} alt='upload' />
        </ShowUploadsDiv>
    )
}
