// import Customers from "./customers";
import Members from "./member";
import Categories from "./categories";
import SingleMemeber from "./member/singleMember";
// import Requests from "./requests";
import Opportunities from "./opportunities";
import Tasks from "./tasks";
import Transactions from "./transactions";
import Loans from "./loans";
// import Users from "./users";
import PerfomancePage from "./performance";
import AdsPage from "./ads";
import Help from "./help";
import Settings from "./settings";
import {
    SINGLEMEMBER,
    // ALLMEMBERS,
    DASHBOARDHOME, 
    // CUSTOMERS,
    // REQUESTS,
    OPPORTUNITIES,
    CATEGORIES,
    TRANSACTIONS,
    PERFORMANCE,
    TASKS,
    LOAN,
    ADSSECTION,
    USERS,
    HELP,
    SETTINGS,
    } from "./ROUTECONST";

const { default: DashboardHome } = require("./home/DashboardHome");

export const dashboardRoutes = [
    {
        path: DASHBOARDHOME,
        component: DashboardHome,
        exact: true,
        protected: true,
    },
    {
        path: OPPORTUNITIES,
        component: Opportunities,
        exact: true,
        protected: true,
    },
    {
        path: SINGLEMEMBER,
        component: SingleMemeber,
        exact: true,
        protected: true,
    },
    {
        path: CATEGORIES,
        component: Categories,
        exact: true,
        protected: true,
    },
    {
        path:TRANSACTIONS,
        component: Transactions,
        exact: true,
        protected: true,
    },
    {
        path: PERFORMANCE,
        component: PerfomancePage,
        exact: true,
        protected: true,
    },
    {
        path: TASKS,
        component: Tasks,
        exact: true,
        protected: true,
    },
    {
        path: LOAN,
        component: Loans,
        exact: true,
        protected: true,
    },
    {
        path: ADSSECTION,
        component: AdsPage,
        exact: true,
        protected: true,
    },
    {
        path: USERS,
        component: Members,
        exact: true,
        protected: true,
    },
    {
        path: HELP,
        component: Help,
        exact: true,
        protected: true,
    },
    {
        path: SETTINGS,
        component: Settings,
        exact: true,
        protected: true,
    },
    
]