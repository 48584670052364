export const DASHBOARDHOME = `/admin`
export const SINGLEMEMBER = `/admin/member/:id`
export const ALLMEMBERS = `/admin/members`
export const REQUESTS = `/admin/requests`
export const CUSTOMERS = `/admin/customers`
export const REFERALS = `/admin/referals`
export const OPPORTUNITIES = `/admin/opportunities`
export const CATEGORIES = `/admin/categories`
export const TRANSACTIONS = `/admin/transactions`
export const PERFORMANCE = `/admin/performance`
export const TASKS = `/admin/tasks`
export const LOAN = `/admin/loan`
export const ADSSECTION = `/admin/ads`
export const USERS = `/admin/users`
export const HELP = `/admin/help`
export const SETTINGS = `/admin/settings`