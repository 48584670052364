import React, { useState } from "react";
import {
  BackArea,
  MemberBox,
  MemberBoxDiv,
  MemberMenu,
  SingleMemberDiv,
} from "./style";
import backArrow from "assets/svg/backArrow.svg";
import SingleProfile from "./profile";
import { ALLMEMBERS } from "../../ROUTECONST";
import { SpottrLink } from "GlobalStyles/spotrrStyles/style";
import TransactionActivities from "./transactionActivities";
import Acitivities from "./activities";

export default function SingleMemeber() {
  const [profileMenu, setProfileMenu] = useState(0);

  function handleMenuChange(num) {
    setProfileMenu(num);
  }

  return (
    <SingleMemberDiv>
      <SpottrLink to={ALLMEMBERS}>
        <BackArea>
          <img src={backArrow} alt="iocn" />
          <p>Go Back</p>
        </BackArea>
      </SpottrLink>
      <MemberBoxDiv>
        <MemberBox>
          <MemberMenu>
            <p
              onClick={() => handleMenuChange(0)}
              className={profileMenu === 0 ? "menu-active" : null}
            >
              Profile
            </p>
            <p
              onClick={() => handleMenuChange(1)}
              className={profileMenu === 1 ? "menu-active" : null}
            >
              Transaction Activities
            </p>
            <p
              onClick={() => handleMenuChange(2)}
              className={profileMenu === 2 ? "menu-active" : null}
            >
              User Activities
            </p>
          </MemberMenu>
          {profileMenu === 0 ? <SingleProfile /> : null}
          {profileMenu === 1 ? <TransactionActivities /> : null}
          {profileMenu === 2 ? <Acitivities /> : null}
        </MemberBox>
      </MemberBoxDiv>
    </SingleMemberDiv>
  );
}
