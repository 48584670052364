import media from "GlobalStyles/utils/media";
import styled from "styled-components/macro";

export const MenuContainer = styled.div`
  width: 80px;
  position: absolute;
  top: 0;
  left: 0;
  max-height: 100vh;
  background: #edf3fa;
  box-shadow: 0px 2.5865px 12.9325px rgba(0, 0, 0, 0.07);
  border-radius: 1.93988px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  overflow: auto;
  ${media.tablet`
        display: none;
    `}
`;

export const MenuItem = styled.div`
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
  margin-top: 15px;
  width: 90px;
  height: 70px;
  padding: 4px;
  color: #929aa7;
  border: none;
  background-color: transparent;
  cursor: pointer;

  &.activeLink {
    background-color: #274b89;
    box-shadow: 0px 2.5865px 12.9325px rgba(0, 0, 0, 0.07);
    border-radius: 1px;
    color: white;
  }
`;
export const MenuText = styled.small`
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  margin-top: 5px;
`;

export const MenuIcon = styled.img`
  margin-top: 10px;
  width: 25px;
  height: 25px;
  margin-left: 6px;
  margin-left:${({ marginLeft }) => (marginLeft ? 20 : null)};
`;

export const BigMenuItemsContainer = styled.div`
  display:flex;
  flex-direction: column;
  height:95vh;
  max-height: 100vh;
  overflow-y:auto;
`;

export const MenuDropdownIcon = styled.img`
   margin-top: 10px;
   width: 10px;
   margin-left:5px
`

export const MenuIconContainer = styled.div`
display:flex;
justify-content: center;
`
export const MobileMenuContainer = styled.div`
  display: none;
  position: absolute;
  top: 0;

  img {
    cursor: pointer;
    width: 33px;
    z-index: 7;
  }

  ${media.tablet`
        /* margin-top: -15px; */
        display: flex;
        height: 50px;
        width: 100%;
        background-color: white;
        padding: 0 0 0 10px;
    `}
`;
