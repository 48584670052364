import React from "react";
import {
  NotificationItemContainer,
  userDpContainer,
  UserDp,
  UserStatusIndicator,
  MessageTextContainer,
  TextBottom,
  Spacer,
  AgoText,
} from "./style";
import gdgd from "../../assets/mem/userdp.png";
import onlineStatus from "../../assets/svg/online_indicator.svg";
import Colors from "GlobalStyles/appColors";

export const NotifiContent = () => {
  return (
    <NotificationItemContainer>
      <userDpContainer>
        <UserDp src={gdgd} alt="user-dp" />
        <UserStatusIndicator>
          <img src={onlineStatus} alt="status online" />
        </UserStatusIndicator>
      </userDpContainer>
      <Spacer />
      <MessageTextContainer>
        <TextBottom color={Colors.primary}>
          Obinna Samuel <AgoText color={Colors.ligterGrey}>2mins ago</AgoText>
        </TextBottom>
        <TextBottom color={Colors.primary}>
          Multi-colored children sportswear
        </TextBottom>
        <TextBottom color={Colors.muted}>
          Lorem ipsum dolor sit amet, consectetur
        </TextBottom>
      </MessageTextContainer>
    </NotificationItemContainer>
  );
};
