import Colors from "GlobalStyles/appColors";
import { Div, SpottrP } from "GlobalStyles/spotrrStyles/style";
import React from "react";
import { TPSellersContainer } from "../style";
import TopSellersItem from "./bestsellersItem";
import TSFilters from "./filterbestsellers";
import { BestSellerscontainer } from "./style";

function BestSellers() {
  return (
    <BestSellerscontainer>
      <Div width="100%" display="flex" justify="space-between" alignI="center" wrap="wrap">
        <SpottrP fw="700" color={Colors.blue}>Best Sellers</SpottrP>
        <TSFilters   otitle='filter by' options={['None','Name','Location', 'Rating', 'List'] }  />
      </Div>
      <TPSellersContainer>
     {[...new Array(12)].map((ts, i) => <TopSellersItem key={i} bg={i % 2 ===0 ? "#E1EFFB" : Colors.lightbrown} /> ) }

      </TPSellersContainer>
    </BestSellerscontainer>
  );
}

export default BestSellers;
