import greyDasboardIcon from "assets/svg/greyHome.svg";
import DashboardIcon from "assets/svg/homeIcon.svg";
import OpportunitiesIcon from "assets/svg/opportunities.svg";
import WhiteOpportunitiesIcon from "assets/tabs/opportunities.svg";
import CategoryIcon from "assets/svg/categories.svg";
import WhiteCategoryIcon from "assets/tabs/categories.svg";
import TransactionsIcon from "assets/svg/transactions.svg";
import WhiteTransactionsIcon from "assets/tabs/transactions.svg";
import performanceIcon from "assets/svg/performance.svg";
import WhiteperformanceIcon from "assets/tabs/performance.svg";
import TasksIcon from "assets/svg/tasks.svg";
import WhiteTasksIcon from "assets/tabs/tasks.svg";
import UserIcon from "assets/svg/users.svg";
import whiteUserIcon from "assets/tabs/users.svg";
import AdsIcon from "assets/svg/ads.svg";
import WhiteAdsIcon from "assets/tabs/ads.svg";
import SettingIcon from "assets/svg/settingIcon.svg";
import whiteSettingIcon from "assets/svg/whiteSettings.svg";
import loanIcon from "assets/svg/loans.svg";
import whiteLoanIcon from "assets/tabs/loans.svg";
import HelpIcon from "assets/svg/requestIcon.svg";
import whiteHelp from "assets/svg/whiteRequest.svg";
import dropDownGrey from  "assets/tabs/sideMenuDropdownIconGrey.svg";
import dropDownWhite from "assets/tabs/sideMenuDropdownIconWhite.svg";
import {
  DASHBOARDHOME,
  // ALLMEMBERS,
  // CUSTOMERS,
  // REQUESTS,
  OPPORTUNITIES,
  CATEGORIES,
  TRANSACTIONS,
  PERFORMANCE,
  TASKS,
  LOAN,
  ADSSECTION,
  USERS,
  HELP,
  SETTINGS,
} from "pages/dashboard/main/ROUTECONST";

export const menuData = [
  {
    icon: greyDasboardIcon,
    white: DashboardIcon,
    text: "Dashboard",
    isActive: true,
    link: DASHBOARDHOME,
    hasDropdown:true,
    dropDownIconWhite:dropDownWhite,
    dropDownIconGrey:dropDownGrey,
  },
  {
    icon: OpportunitiesIcon,
    white: WhiteOpportunitiesIcon,
    text: "Opportunities",
    isActive: false,
    link: OPPORTUNITIES,
    hasDropdown:false,
    dropDownIconWhite:"",
    dropDownIconGrey:"",
  },
  {
    icon: CategoryIcon,
    white: WhiteCategoryIcon,
    text: "Categories",
    isActive: false,
    link: CATEGORIES,
    hasDropdown:false,
    dropDownIconWhite:"",
    dropDownIconGrey:"",
  },
  {
    icon: TransactionsIcon,
    white: WhiteTransactionsIcon,
    text: "Transactions",
    isActive: false,
    link: TRANSACTIONS,
    hasDropdown:true,
    dropDownIconWhite:dropDownWhite,
    dropDownIconGrey:dropDownGrey,
  },
  {
    icon: performanceIcon,
    white: WhiteperformanceIcon,
    text: "Performance",
    isActive: false,
    link: PERFORMANCE,
    hasDropdown:false,
    dropDownIconWhite:"",
    dropDownIconGrey:"",
  },
  {
    icon: TasksIcon,
    white: WhiteTasksIcon,
    text: "Tasks",
    isActive: false,
    link: TASKS,
    hasDropdown:false,
    dropDownIconWhite:"",
    dropDownIconGrey:"",
  },
  {
    icon: AdsIcon,
    white: WhiteAdsIcon,
    text: "Ads Section",
    isActive: false,
    link: ADSSECTION,
    hasDropdown:false,
    dropDownIconWhite:"",
    dropDownIconGrey:"",
  },
  {
    icon: loanIcon,
    white: whiteLoanIcon,
    text: "Loans",
    isActive: false,
    link: LOAN,
    hasDropdown:false,
    dropDownIconWhite:"",
    dropDownIconGrey:"",
  },
  {
    icon: UserIcon,
    white: whiteUserIcon,
    text: "Users",
    isActive: false,
    link: USERS,
    hasDropdown:true,
    dropDownIconWhite:dropDownWhite,
    dropDownIconGrey:dropDownGrey,
  },
  {
    icon: HelpIcon,
    white: whiteHelp,
    text: "Help",
    isActive: false,
    link: HELP,
    dropDownIconWhite:"",
    dropDownIconGrey:"",
  },
  {
    icon: SettingIcon,
    white: whiteSettingIcon,
    text: "Settings",
    isActive: false,
    link: SETTINGS,
    hasDropdown:false,
    dropDownIconWhite:"",
    dropDownIconGrey:"",
  },
];
