import { Div } from "GlobalStyles/spotrrStyles/style";
import React, { useState } from "react";
import {
  MemberBoxDiv,
  MemberMenu,
} from "../member/singleMember/style";
import OpportunitiesHead from "./head";
import Productsopportunities from "./products";

function Opportunities() {
  const [opportunitiesMenu, setOpportunitiesMenu] = useState(0);

  function handleTabChange(num) {
    setOpportunitiesMenu(num);
  }
  return (
    <MemberBoxDiv>
      <Div display="flex" flexDirection="column">
        <OpportunitiesHead />
        <MemberMenu mt="15px" mb="10px">
          <p
            onClick={() => handleTabChange(0)}
            className={opportunitiesMenu === 0 ? "menu-active" : null}
          >
            Products
          </p>
          <p
            onClick={() => handleTabChange(1)}
            className={opportunitiesMenu === 1 ? "menu-active" : null}
          >
            Ads
          </p>
        </MemberMenu>

        {/* {opportunitiesMenu} */}
        {opportunitiesMenu === 0 ? <Productsopportunities /> : null}
        {opportunitiesMenu === 1 ? <Productsopportunities /> : null}
      </Div>
    </MemberBoxDiv>
    // </div>
  );
}

export default Opportunities;
