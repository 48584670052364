import media from 'GlobalStyles/utils/media'
import styled from 'styled-components/macro'

export const PerformanceHeadDiv = styled.div`
    display: flex;
    align-items: center;
    margin-top: 10px;
`
export const PHeadText = styled.p`
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 25px;
    display: flex;
    align-items: center;
    color: #274B89;

`

export const PBackAreaContent = styled.div`
    display: flex;
    p{
        font-family: Nunito;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: #274B89;
        margin-left: 15px;
        cursor: pointer;
    }

    ${media.tablet`
    width:50%;
    `}
    ${media.mobile`
     width:100%;
        `}
    ${media.smallMobile`
    width:width:100%;
`}
`;

