import styled from 'styled-components/macro'


export const ProfileDropdownContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px;
    position: absolute;
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    width: 190px;
    height: 192px;
    margin-top: -55px;
    z-index:10;
    top: 124px;
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
`;

export const DropdownProfileDp = styled.img`
    width:25px; 
    height:25px;
`;

export const DropdownMenuLabel = styled.p`
    color: ${({color}) => color || null};
`;
export const MenuLabelConatainer = styled.div`
    padding-top:10px;
    padding-bottom: 10px
`;