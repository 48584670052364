import React from "react";
import Filters from "./filters";
import {
  BackAreaContent,
  CategoriesHeadDiv,
  CategoryheaderDiv,
  CHeadText,
  CSearch,
} from "./style";
import { ALLMEMBERS } from "../../ROUTECONST";
import { Div, SpottrLink, SpottrP } from "GlobalStyles/spotrrStyles/style";
import backArrow from "assets/svg/backArrow.svg";
import { Spacer } from "components/Notification/style";
export default function CategoriesHead({ input, setInput }) {
  console.log(input);
  return (
    <CategoriesHeadDiv>
      <BackAreaContent>
        <SpottrLink to={ALLMEMBERS}>
          <Div width="120px" display="flex">
            <img src={backArrow} alt="icon" />
            <SpottrP fs="12px">Go Back</SpottrP>
          </Div>
        </SpottrLink>
        <CHeadText>Categories</CHeadText>
      </BackAreaContent>
      <CategoryheaderDiv display="flex" w="100%">
        <Spacer />
        <CSearch
          onChange={(e) => setInput(e.target.value)}
          placeholder="Search Tasks"
        />
        <Filters
          title="filter by"
          options={["None", "Name", "Location", "Rating", "List"]}
        />
      </CategoryheaderDiv>
    </CategoriesHeadDiv>
  );
}
