import styled from 'styled-components/macro'

export const TopSellersContainer = styled.div`
    min-Width: 151.83px;
    Height: 76.75px;
    background: ${({bg})=>bg === 'blue'?'#E1EFFB':'#FFF0CF'} ;
    border: 0.800869px solid rgba(154, 159, 191, 0.25);
    box-sizing: border-box;
    border-radius: 2.50272px;
    display: flex;
    justify-content: space-between;
    /* margin-right: 10px; */
`

export const TopsellerImage = styled.img`
    border-radius: 50%;
    /* border: 2px solid #C2E0FF; */
    width: 60px;
`

export const TotalDiv = styled.div`
    background: rgba(227, 232, 240, 0.51);
    border-radius: 2.50272px;
    padding: 3px;
    display: flex;
    margin-bottom: 5px;
`

export const DetailsDiv = styled.div`
    padding: 10px 4px 10px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
 
`
export const NameText = styled.p`
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 10.0109px;
    line-height: 14px;
    color: #061E48;
`
export const LocationText = styled.p`
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 5.83967px;
    color: #929AA7;
`

export const TotalText = styled.p`
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 6.67391px;
    line-height: 9px;
    text-align: center;
    color: #274B89;
    margin: 0 0 0 4px;
`