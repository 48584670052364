import React, { useState } from "react";

import { Button, Div, Img } from "GlobalStyles/spotrrStyles/style";
import { MemberBoxDiv, MemberMenu } from "../member/singleMember/style";
import TasksHead from "./head";
import NewTasks from "./newTasks";
import ActiveTasks from "./activeTasks";
import CompletedTasks from "./completedTasks";

import firstBarge from "assets/svg/redBargeFull.svg";
import secondBarge from "assets/svg/greenBargeFull.svg";
import thirdBarge from "assets/svg/yellowBargeFull.svg";
import { Spacer } from "components/Notification/style";
import Colors from "GlobalStyles/appColors";

function Tasks() {
  const [taskmenu, setTaskMenu] = useState(0);

  function handleChangeTaskMenu(num) {
    setTaskMenu(num);
  }
  return (
    <div>
      <MemberBoxDiv>
        <Div display="flex" flexDirection="column">
          <TasksHead />
          <Div display="flex" alignI="center">
            <Img src={firstBarge} w="120px" h="120px" alt="1St" />
            <Img
              src={secondBarge}
              w="130px"
              h="150px"
              alt="2nd"
              m="15px 0px 0px 0px"
            />
            <Img src={thirdBarge} w="120px" h="120px" alt="3rd" />
          </Div>

          <Div display="flex" alignI="center">
            <MemberMenu mt="15px" mb="10px">
              <p
                onClick={() => handleChangeTaskMenu(0)}
                className={taskmenu === 0 ? "menu-active" : null}
              >
                New
              </p>
              <p
                onClick={() => handleChangeTaskMenu(1)}
                className={taskmenu === 1 ? "menu-active" : null}
              >
                Active
              </p>
              <p
                onClick={() => handleChangeTaskMenu(2)}
                className={taskmenu === 2 ? "menu-active" : null}
              >
                Completed
              </p>
            </MemberMenu>
            <Spacer />
            <Div>
              <Button
                color={Colors.primary}
                m="0px 15px"
                bc={Colors.white}
                border={Colors.lightGreen}
                p="10px 20px"
                br="3px"
                fw="bold"
              >
                {"Create a new task  +"}{" "}
              </Button>
              <Button
                color={Colors.danger}
                bc={Colors.white}
                border={Colors.danger}
                p="10px 20px"
                br="3px"
                fw="bold"
              >
                Delete Task x{" "}
              </Button>
            </Div>
          </Div>

          {/* {opportunitiesMenu} */}
          {taskmenu === 0 ? <NewTasks /> : null}
          {taskmenu === 1 ? <ActiveTasks /> : null}
          {taskmenu === 2 ? <CompletedTasks /> : null}
        </Div>
      </MemberBoxDiv>
    </div>
  );
}

export default Tasks;
