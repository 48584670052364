import React from 'react'
import { DetailsDivBs, TLocationText, BsNameText, TopsellerImageItem, TopSellersContainerPage, TotalDivBs, TotalTxt } from './style'
import LadyPic from 'assets/svg/lady.svg'
import pin from 'assets/svg/pin.svg'
import { Div } from 'GlobalStyles/spotrrStyles/style'


export default function TopSellersItem({image={LadyPic}, bg='#C2E0FF', name='John Tommy', location='Lagos, Nigeria', total='4,509'}) {
    return (
        <TopSellersContainerPage bg={bg} >
            <TopsellerImageItem src={LadyPic} alt='seller' />
            <DetailsDivBs>
                <Div>
                    <BsNameText>{name}</BsNameText>
                    <TLocationText>{location}</TLocationText>       
                </Div>
                <TotalDivBs>
                    <img src={pin} alt='pin'/>
                    <TotalTxt> {total} items bought</TotalTxt>
                </TotalDivBs>
            </DetailsDivBs>
        </TopSellersContainerPage>
    )
}
