import DropDown from 'components/onboading/describeForm/dropDown'
import React, { useEffect, useState } from 'react'
import { GETCATEGORIES } from 'routes/backend'
import { CountDiv,DescribeForm,DescribeArea, DescribeCompanyContainer, LocationText } from './style'

export default function DescribeCompany({corporateUser, setCorporateUser}) {
    const { description } = corporateUser.data
    const [categories, setCategories] = useState([])


    function handleChange(e){
        setCorporateUser({
            ...corporateUser,
            data:{
                ...corporateUser.data,
                [e.target.name]:e.target.value
            }
        })
    }

    useEffect(()=>{
        fetch(GETCATEGORIES)
            .then(res=>res.json())
            .then(data=>{
                // console.log('catData',data)
                setCategories([...data.data])
            })
            .catch(err=>console.log(err))
    },[])
    return (
        <DescribeCompanyContainer>
            {console.log("brandName",corporateUser)}
            <LocationText>Decribe your company</LocationText>
            <CountDiv>
                <p style={{color:'black'}}>What does your company do?</p>
                <p>{description.length === 0? 0:description.length}/500</p>
            </CountDiv>
            <DescribeForm>
                <DescribeArea value={description || ''} maxLength={'500'} onChange={handleChange} name='description' placeholder='Write Something...' />
                <DropDown handleChange={handleChange} data={categories} />
                {/* <OtherTag corporateUser = {corporateUser} setCorporateUser = {setCorporateUser} /> */}
            </DescribeForm>
        </DescribeCompanyContainer>
    )
}
