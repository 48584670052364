import React, { useEffect, useState } from 'react'
import Modal from 'components/UI/Modal'
import UploadImages from './uploadImages'
import { LoginButton } from 'pages/Login/style'
import { AddProductDiv, ProductMainArea } from './style'
import { useCorporateProduct } from 'context/addProduct'
// import { UPLOADMULTIPLE } from 'routes/backend'
import ShowUploads from './showUploads'
import ServiceProduct from './serviceProduct'
import { GETCATEGORIES } from 'routes/backend'

export default function AddProduct() {
    const {corporateProduct, setCorporateProduct} = useCorporateProduct()
    const {page, showProductModal} = corporateProduct
    const [fileSrc, setFileSrc] = useState()
    const [fileName, setFileName] = useState()
    const [categories, setCategories] = useState([])

    function closeProductModal(){
        setCorporateProduct({
            ...corporateProduct,
            showProductModal: false
        })
    }
    function onContinue(){
        setCorporateProduct({
            ...corporateProduct,
            page: page + 1
        })
    }
    function onBack(){
        if(page>0)
        setCorporateProduct({
            ...corporateProduct,
            page: page - 1
        })
    }

    async function handleFile(e){  
        const file = await URL.createObjectURL(e.target.files[0]);
        const myFile = await e.target.files[0]
        setFileSrc(file)
        setFileName(myFile.name)
        console.log(myFile)
        console.log('ee',e.target.files)
        console.log(fileSrc)
        console.log(fileName)        
        console.log(categories)
      // Update the formData object
      let formdata = new FormData();
      formdata.append("file", myFile, file);
      
    //   let requestOptions = {
    //     method: 'POST',
    //     body: formdata,
    //     redirect: 'follow'
    //   };
      
    //   fetch(UPLOADMULTIPLE, requestOptions)
    //     .then(response => response.json())
    //     .then(res => {
    //         console.log(res)
    //         if(res.status === true){
    //             setCorporateUser({
    //                 ...corporateUser,
    //                 data:{
    //                     ...corporateUser.data,
    //                     profileUrl: res.data.url
    //                 }
    //             })
    //         }
    //     })
    //     .catch(error => console.log('error', error))
    }

    useEffect(()=>{
        fetch(GETCATEGORIES)
            .then(res=>res.json())
            .then(data=>{
                setCategories([...data.data])
            })
            .catch(err=>console.log(err))
    },[])

    return (
            <Modal show={showProductModal} modalClosed={closeProductModal} >
                <AddProductDiv>
                    {console.log(page)}
                    <ProductMainArea>
                        {page===0?<UploadImages handleFile ={handleFile} /> : null}
                        {page===1?<ShowUploads handleFile ={handleFile} /> : null}
                        {page===2?<ServiceProduct handleFile ={handleFile} /> : null}
                    </ProductMainArea>
                    <LoginButton onClick={onContinue} style={{width:'300px',marginTop:'50px'}}>Continue</LoginButton>
                    <p onClick={onBack}>back</p>
                </AddProductDiv>
            </Modal>
    )
}
