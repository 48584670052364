import React, { useRef, useState } from 'react'
import SocialButton from 'components/onboading/socialButton'
import { Div } from 'GlobalStyles/spotrrStyles/style'
import facebook from 'assets/svg/facebook.svg'
import google from 'assets/svg/google.svg'
import backArrow from 'assets/svg/backArrow.svg'
import { LoginButton, LoginText, UseSocials } from 'pages/Login/style'
import { BackText, RegContainer, SignupForm } from './style'
import EmailForm from './email'
import { useRegContext } from 'context/RegContext'
import OTPForm from './otp'
// import PasswordForm from './password'
import { ONBOARDING } from 'routes/routes'
import asyncFetch from 'utilities/asyncFetch'
import { SENDOTP, SIGNUPUSER, VALIDATEOTP } from 'routes/backend'
import * as EmailValidator from 'email-validator';
import Toast  from 'components/UI/Toast/index'
import { useCorporateUser } from 'context/userDetails'
// import { useAuthContext } from 'context/AuthContext'
// import SwitchButton from 'components/onboading/switchField'

export default function RegForm() {
    const {regData, setRegData} = useRegContext()
    // const {user, setUser} = useAuthContext()
    const {corporateUser, setCorporateUser} = useCorporateUser()
    const [passwordScore, setPasswordScore] = useState()
    const {pageCount} = regData
    // const errRef = useRef('')
    const loginRef = useRef('')
    const [isDisabled, setIsDisabled] = useState(false)
    const [errMessage, setErrMessage] = useState('')
    const [showModal, setShowModal] = useState(false)


    // function setError(errMessage){
    //     errRef.current.textContent = errMessage
    //     setTimeout(()=>{
    //         errRef.current.textContent = ''
    //     },3000)
    // }

    function sendOtp(emailObj){
        const _ = undefined
        asyncFetch('POST', _ , emailObj , SENDOTP)
        .then(res=>{
            console.log(res.status)
            setIsDisabled(false)
            loginRef.current.textContent = 'Continue'
            if(res.status===false){
                setErrMessage(res.message)
                setShowModal(true)
                setRegData({
                    ...regData,
                    backOtp: res.data.token,
                    pageCount: 0
                });
                return
            }
            setRegData({
                ...regData,
                backOtp: res.data.token,
                pageCount: 1
            });
        })
        .catch(err=>{
            console.error(err)
            setIsDisabled(false)
            return
        })
    }

    function onNext(e){
        e.preventDefault();
        setIsDisabled(true)
        const _ = undefined
        if(pageCount===0){
            const {email, phoneNumber} = regData.form
            const isEmail = !!email
            const isPhone = !!phoneNumber
            const isEmailValidated = EmailValidator.validate(email)
            loginRef.current.textContent = 'loading...'
            if(!isEmail){
                const errorMessage = 'please input email address'
                setErrMessage(errorMessage)
                setShowModal(true)
                setIsDisabled(false)
                loginRef.current.textContent = 'Continue'
                return;
            }else if( !isPhone ){
                const errorMessage = 'please input phone number'
                setErrMessage(errorMessage)
                setShowModal(true)
                setIsDisabled(false)
                loginRef.current.textContent = 'Continue'
                return;
            }
            if(!isEmailValidated){
                const errorMessage = 'please input a valid email'
                setErrMessage(errorMessage)
                setShowModal(true)
                setIsDisabled(false)
                loginRef.current.textContent = 'Continue'
                return;
            }
            if(passwordScore < 3){
                const errorMessage = 'Password not strong enough'
                setErrMessage(errorMessage)
                setShowModal(true)
                setIsDisabled(false)
                loginRef.current.textContent = 'Continue'
                return;
            }
            asyncFetch('POST', _ , regData.form , SIGNUPUSER)
                .then(res=>{
                    loginRef.current.textContent = 'Continue'
                    setIsDisabled(false)
                    console.log(regData)
                    console.log(res)
                    if(res.status===true){
                        setCorporateUser({
                            ...corporateUser,
                            token: res.data.token,
                        })
                        sendOtp({phoneNumber})
                        setRegData({
                            ...regData,
                            // backOtp: res.data.token,
                            pageCount: 1
                        });
                        //continue login
                        // window.location.href = ONBOARDING
                        return;
                    }else if(res.status===false){
                        setErrMessage(res.message)
                        setShowModal(true)
                        
                    }
                })
                .catch(err=>{
                    loginRef.current.textContent = 'Continue'
                    console.error(err)
                })

            return;
        }
        if(pageCount===1){
            // const errorMessage = ''
            const {email} = regData.form
            const token = regData.form.OTP
            // const {backOtp} = regData
            // const {OTP} = regData.form
            loginRef.current.textContent = 'Loading...'
            // if(backOtp !== OTP){
            //     const errorMessage = 'Otp Validation Failed'
            //     setErrMessage(errorMessage)
            //     setShowModal(true)
            //     setIsDisabled(false)
            //     loginRef.current.textContent = 'Continue'
            //     return;
            // }   
            // if(backOtp === OTP){
            //     //continue login
            //     const errorMessage = 'Otp Validation Succcessful'
            //     setErrMessage(errorMessage)
            //     setShowModal(true)
            //     setRegData({
            //         ...regData,
            //         pageCount: regData.pageCount + 1
            //     });
            //     return;
            // }
            const _ = undefined
                asyncFetch('POST', _ , {email,token} , VALIDATEOTP)
                .then(res=>{
                    setIsDisabled(false)
                    loginRef.current.textContent = 'Continue'
                    console.log(res)
                    if(res.status===true){
                        //continue login
                        // setUser({
                        //     ...user,
                        //     token:corporateUser.token
                        // })
                        const errorMessage = 'Otp Validation Succcessful'
                        setErrMessage(errorMessage)
                        setShowModal(true)
                        window.location.href = ONBOARDING
                        // setRegData({
                        //     ...regData,
                        //     pageCount: 
                        // });
                        return;
                    }else if(res.status===false){
                        setErrMessage(res.message) 
                        setShowModal(true) 
                        setIsDisabled(false)
                        loginRef.current.textContent = 'Continue'                     
                        return;
                    }
                })
            
                // setErrMessage(errorMessage)
                // setShowModal(true)
                setIsDisabled(false)
                return;
        }
        if(pageCount===2) {
            if(passwordScore < 3){
                const errorMessage = 'Password not strong enough'
                setErrMessage(errorMessage)
                setShowModal(true)
                setIsDisabled(false)
                return;
            }
            if(passwordScore >= 3){
                loginRef.current.textContent = 'loading...'
                const _ = undefined
                asyncFetch('POST', _ , regData.form , SIGNUPUSER)
                .then(res=>{
                    loginRef.current.textContent = 'Submit'
                    setIsDisabled(false)
                    console.log(regData)
                    console.log(res)
                    if(res.status===true){
                        //continue login
                        setCorporateUser({
                            ...corporateUser,
                            token: res.data.token
                        })
                        window.location.href = ONBOARDING
                    }else if(res.status===false){
                        setErrMessage(res.message)
                        setShowModal(true)
                        
                    }
                })
                .catch(err=>{
                    loginRef.current.textContent = 'Submit'
                    console.error(err)
                })
            }
            return
        }
        setRegData({
            ...regData,
            pageCount: regData.pageCount + 1
        });
    }
    function onPrev(){
        if(pageCount === 2){
            const err = 'Please Continue Registration'
            setErrMessage(err)
            setShowModal(true)
            // return;
        }
        if(pageCount > 0)
        setRegData({
            ...regData,
            pageCount: regData.pageCount - 1
        });
    }

    function handleFormChange(e){
        setRegData({
            ...regData,
            form:{
                ...regData.form,
                [e.target.name]:e.target.value
            }
        })
    }


    return (
        <RegContainer>
            <Toast icon='error' description={errMessage} showModal={showModal} setShowModal={setShowModal} />
            {console.log('reg',regData)}
            {console.log('cup',corporateUser)}
            <Div onClick={onPrev} margin='0 0 50px 0' display='flex'>
                <img src={backArrow} alt='' />
                <BackText>Go Back</BackText>
            </Div>
            <LoginText>Create an account</LoginText>
            {/* <SwitchButton /> */}
            <SignupForm>
                {pageCount===0?<EmailForm handleFormChange={handleFormChange} 
                                          passwordScore={passwordScore} 
                                          setPasswordScore={setPasswordScore} />:null}
                {pageCount===1?<OTPForm handleFormChange={handleFormChange} />:null}
                {/* {pageCount===2?<PasswordForm passwordScore = {passwordScore} 
                                            setPasswordScore = {setPasswordScore} 
                                            handleFormChange={handleFormChange} />:null} */}
                <UseSocials>
                    <p>Or use</p>
                    <Div display='flex' margin='40px 0' width='100%' justify= 'center'>
                        <SocialButton icon={facebook} />
                        <SocialButton icon={google} />
                    </Div>
                </UseSocials>
                {/* <small style={{color:'red',textAlign:'center'}} ref={errRef}></small> */}
                <LoginButton  disabled={isDisabled} ref={loginRef} onClick={onNext}>{pageCount===2?'Submit':'Continue'}</LoginButton>
            </SignupForm>
        </RegContainer>
    )
}
