import { Div, Img, SpottrP } from "GlobalStyles/spotrrStyles/style";
import React from "react";
import image from "assets/opImage.png";
import Colors from "GlobalStyles/appColors";
function ProductItem() {
  return (
    <Div
    //   border="0.96px solid rgba(154, 159, 191, 0.25)"
      boxShadow="0px 4px 20px rgba(0, 0, 0, 0.07)"
      backgroundColor="#FFF"
      padding="5px"
      width="316px"
      height="120px"
      margin="0px 10px 10px 5px"
      br="5px"

    >
      <Div display="flex">
        <Img w="70px" height="70px" br="3px" src={image} alt={image} />
        <Div
        margin="0px 0px 0px 10px"
        width="100%"
          display="flex"
          flexDirection="column"
        >
          <Div>
            <SpottrP fs="15px" fw="bold" color={Colors.darkblue}>
              10 Baskets of Tomatoes
            </SpottrP>
            <SpottrP fs="14px" fw="bold" color={Colors.blue}>
              Food Services
            </SpottrP>
            <SpottrP fs="12px" color={Colors.darkblue}>Coker & Sons Ltd</SpottrP>
          </Div>
          <Div
            width="100%"
            display="flex"
            justify="space-between"
            alignI="center"
            margin="15px 0px 0px 0px"
          >
            <SpottrP fs="12px" fw="bold" color={Colors.ligterGrey}>Lagos, Nigeria</SpottrP>
            <Div
              display="flex"
              justify="flex-end"
              alignI="center"
            >
              <Div
                br="3px"
                padding="5px 10px"
                width="100%"
                backgroundColor={Colors.lightGreen}
              >
                <SpottrP fs="12px" >N4,800,000</SpottrP>
              </Div>
              <SpottrP fs="9px" ml="5px"> /day</SpottrP>
            </Div>
          </Div>
        </Div>
      </Div>
    </Div>
  );
}

export default ProductItem;
