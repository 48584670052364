import React from "react";
import { Div } from "GlobalStyles/spotrrStyles/style";
import SingleTaskItemActive from "../taskItem/singleTaskActive";
import Colors from "GlobalStyles/appColors";


function ActiveTasks() {
  return (
    <Div display="flex" wrap="wrap">
      {[...new Array(12)].map((product, i) => (
        <SingleTaskItemActive
         key={i} statusBgColor={Colors.lightyellow} 
        statusTextColor={Colors.yellow} 
        title="Multi-Colored Children’s Sportwear"
        statusText="Active"  />
      ))}
    </Div>
  );
}

export default ActiveTasks;
