import Colors from "GlobalStyles/appColors";
import media from "GlobalStyles/utils/media";
import styled from "styled-components/macro";

export const HeaderContainer = styled.div`
  height: 100px;
  width: 100%;
  border-bottom: 1px solid #c2e0ff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 2px;

  ${media.tablet`
        display:none;
    `}
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CorporateHeader = styled.p`
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: #274b89;
`;

export const CompanyName = styled.p`
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #929aa7;
`;

export const NotificationArea = styled.div`
  display: flex;
  justify-content: space-around;
  width: 250px;
`;
export const NotificationDiv = styled.div`
display: flex;
justify-content: space-between;
`;
export const NotificationIcon = styled.img`
  margin-right: 7px;
  cursor: pointer;
`;
export const ImageDiv = styled.div`
  display: flex;

  img {
    cursor: pointer;
  }
`;
export const ProfilePicture = styled.img`
  width: 40px;
  border: 2px solid #274B89;
  height: 40px;
  margin-right: 5px;
  border-radius: 50%;
`;

export const HeaderMenuArea = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;
export const HeaderMenus = styled.div`
  display: flex;
  margin-left: -70px;
`;

export const SpottrLogo = styled.img`
  width: 80px;
  margin-right: 100px;
`;
export const SingleMenuDiv = styled.div`
  margin-right: 45px;
  margin-bottom:-6px;
   .menu-active {
     border-bottom: 2px solid ${Colors.blue};
   }
  p {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    display: flex;
    align-items: center;
    letter-spacing: -0.408px;

    color: #929aa7;
  }
`;

export const AddNewCategories = styled.p`
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 11.0995px;
  line-height: 17px;
  color: #274b89;
`;

export const CreateTaskLinkContainer = styled.div`
  display: flex;
  width: 250px;
  justify-content: space-around;
`;

export const MessageNotificationContainer = styled.div`
display: flex;
margin-left: 10px;
`;
export const MessageCountIndicator = styled.div`
 height: 80%;
 width:80%;
 display: flex;
 justify-content:center;
 align-items:center;
 border-radius:50%;
 background-color:#FF4B3E;
 color:#FFF;
 font-size:10px;
 cursor: pointer;
 
`;
export const WhiteCircle = styled.div`
  display: flex;
  height: 30px;
  width:43px;
  border-radius:50%;
  justify-content:center;
  align-items:center;
  position:sticky;
  background-color:#FFF;
  margin-left:-18px;
  margin-top: -3px;

`;

