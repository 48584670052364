import React, { useEffect, useState } from "react";
import MembersHead from "./head";
import { MembersDiv } from "./style";
import Table from "./table";
import { membersData } from "./table/data";
// import Pagination from "./table/pagination";

export default function Members() {
  const [myInput, setMyInput] = useState("");
  const [result, setResult] = useState([]);

  useEffect(() => {
    const data = !myInput
      ? membersData
      : membersData.filter(
          (data) =>
            data.name.toLowerCase().includes(myInput.toLowerCase()) ||
            data.email.toLowerCase().includes(myInput.toLowerCase()) ||
            data.status.toLowerCase().includes(myInput.toLowerCase())
        );
    setResult([...data]);
  }, [myInput]);

  return (
    <MembersDiv>
      {/* <DistributionModal /> */}
      {/* <BroadcastMessage /> */}
      <MembersHead input={myInput} setInput={setMyInput} />
      <Table input={myInput} result={result} />
      {/* <Pagination /> */}
    </MembersDiv>
  );
}
