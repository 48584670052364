import media from 'GlobalStyles/utils/media'
import styled from 'styled-components/macro'

export const TableDiv = styled.div`
    width: 100%;

    table{
        width: 100%;
        margin-top: 10px;
        font-size: 12px;

        thead{
            font-weight: bold;
        }

        ${media.tablet`
            min-width: 1000px;
        `}

        tbody{
            background-color: #F6F6F6;
        }
    }
`