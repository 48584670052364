import { useRegContext } from "context/RegContext";
import React, { useEffect, useState } from "react";
import { LabelText } from "../inputField/style";
import { OTPContainer, OTPInput } from "./style";

export default function OTPField() {
  const { setRegData } = useRegContext();

  const [inputOtp, setInputOtp] = useState({
    first: "",
    second: "",
    third: "",
    forth: "",
  });

  function handleOTP(e) {
    setInputOtp({
      ...inputOtp,
      [e.target.name]: e.target.value,
    });
  }

  useEffect(() => {
    const { first, second, third, forth } = inputOtp;
    setRegData((prev) => {
      return {
        ...prev,
        form: {
          ...prev.form,
          OTP: `${first}${second}${third}${forth}`,
        },
      };
    });
  }, [inputOtp, setRegData]);

  return (
    <>
      <LabelText show={"block"}>Enter OTP sent to your phone number</LabelText>
      <OTPContainer>
        <OTPInput onChange={handleOTP} name={"first"} maxLength={1} />
        <OTPInput onChange={handleOTP} name={"second"} maxLength={1} />
        <OTPInput onChange={handleOTP} name={"third"} maxLength={1} />
        <OTPInput onChange={handleOTP} name={"forth"} maxLength={1} />
      </OTPContainer>
    </>
  );
}
