import React  from "react";
import { LoginButton } from "pages/Login/style";
import ImageIcon from "assets/svg/addProductImageIcon.svg";
import closebtn from "assets/svg/closeadninbtn.svg";
import {
  // AddCategoryDiv,
  AddnewCategoryContainer,
  // CategoryMainArea,
  CloseAdminModal,
  UploadImageDiv2,
} from "./style";
// import { useCorporateProduct } from "context/addProduct";

// import { GETCATEGORIES } from "routes/backend";
import AdminBackdrop from "components/UI/AdminModal";
import { HeightSpacer, ImagePicker } from "../style";
import { SpottrP } from "GlobalStyles/spotrrStyles/style";
import Colors from "GlobalStyles/appColors";

export default function AddCategory({ show, close }) {
  // const { corporateProduct, setCorporateProduct } = useCorporateProduct();
  // const [fileSrc, setFileSrc] = useState();
  // const [fileName, setFileName] = useState();
  // const [categories, setCategories] = useState([]);

  // function closeProductModal() {
  //   setCorporateProduct({
  //     ...corporateProduct,
  //     showProductModal: true,
  //   });
  // }
  // function HandleCloseModal() {
  //   //   setAdminModalClose(false)
  // }
  async function handLeChange(e) {
    const file = await URL.createObjectURL(e.target.files[0]);
    const myFile = await e.target.files[0];
    // setFileSrc(file);

    // Update the formData object
    let formdata = new FormData();
    formdata.append("file", myFile, file);
  }

  return (
    <AdminBackdrop showAdminModal={show}>
      <AddnewCategoryContainer>
        <CloseAdminModal>
          <div onClick={close}>
            <img src={closebtn} alt="Close button" />
          </div>
        </CloseAdminModal>
        <SpottrP ml="20px" color={Colors.primary}>
          Add new Category
        </SpottrP>
        <UploadImageDiv2>
          <ImagePicker type="file" onChange={(e) => handLeChange(e)} />
          <img src={ImageIcon} alt="icon" />
          <small>Upload Images</small>
          <HeightSpacer h="20px" />
          <input type="string" placeholder="Category Title" />
          <HeightSpacer h="20px" />
          <LoginButton p="15px 25px">Add Category</LoginButton>
        </UploadImageDiv2>
      </AddnewCategoryContainer>
    </AdminBackdrop>
  );
}
