import React from "react";
import { TopSellersFilterDiv } from "./bestsellersItem/style";

export default function TSFilters({ title, options }) {
  return (
    <TopSellersFilterDiv>
      <p>{title}</p>
      <select>
        {options?.map((option, i) => (
          <option key={i}>{option}</option>
        ))}
      </select>
    </TopSellersFilterDiv>
  );
}
