import styled from 'styled-components/macro';

export const BestSellerscontainer = styled.div`
    width: 369px;
    margin-top:10px;
    margin-right:10px;
    height: 100%;
    padding:10px 5px;
    background: #FFFFFF;
    flex: 0 0 369px;
    border: 1px solid #E1EFFB;
    box-sizing: border-box;
    border-radius: 10px;
`;