import Colors from "GlobalStyles/appColors";
import { Div } from "GlobalStyles/spotrrStyles/style";
import React from "react";
import SingleTaskItemActive from "../taskItem/singleTaskActive";


function CompletedTasks() {
  return (
    <Div display="flex" wrap="wrap">
      {[...new Array(12)].map((product, i) => (
        <SingleTaskItemActive key={i} 
        statusBgColor={Colors.lightergreen}
        title="Multi-Colored Children’s Sportwear"
        statusTextColor={Colors.lightgreen2} 
        statusText="Completed" />
      ))}
    </Div>
  );
}

export default CompletedTasks;
