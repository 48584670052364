import styled from 'styled-components/macro'
import media from "GlobalStyles/utils/media";

export const AddnewCategoryContainer = styled.div`
    height:400px;
    width:400px;
    padding: 20px 10px;
    background: #FFFFFF;
    box-shadow: 0px 2.95622px 14.7811px rgba(0, 0, 0, 0.07);
    border-radius: 2.21716px;
    ${media.tablet`
    width:80%;
    `}
    ${media.mobile`
    width:340px;
        `}
    ${media.smallMobile`
    width:350px;
`}
`;


export const AddCategoryDiv = styled.div`
    width: 100%;
    height: 100%;
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

`
export const CategoryMainArea = styled.div`
    width: 50%;
    height: 50%;
    max-height: 300px;
    overflow: auto;
    display:flex;
    flex-direction: column;
    align-items: center;
`;

export const UploadImagesDiv = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;

    img{
        height: 50px;
        width: 50px;
        margin-top: 60px;
    }

    small{
        font-family: Nunito;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: #274B89;
        margin-top: 20px;
    }
`;

export const UploadImageDiv2 = styled.div`
    display:flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 25px;
    z-index:1000;
    img{
        height: 50px;
        width: 50px;
        margin-top: 20px;
    }
    small{
        font-family: Nunito;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 19px;
        text-align: center;
        color: #274B89;
        margin-top: 20px;
    }
    input {
        background: #F8F8F8;
        border-radius: 5px;
        outline: none;
        height: 54px;
        padding:0px 15px;
        background: #F8F8F8;
        width:100%;
        border:none;
    }
    input:focus {
        background: #ECF7FF;
    }
`;


export const CloseAdminModal = styled.div`
    display: flex;
    justify-content:flex-end;
    div{
        cursor:pointer;
    }
`;

