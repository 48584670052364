import React from "react";
import AddCorporateProduct from "./addProduct.jsx";
import AdminModalContext from "./AdminModalContext.jsx";
import Authorize from "./AuthContext.jsx";
import ToggleDropdownMenuContext from "./DropownMenuContext.jsx";
import OnboardingUser from "./onboarding.jsx";
import Registration from "./RegContext.jsx";
import CorporateUser from "./userDetails.jsx";
function Store({ children }) {
  return (
    <Authorize>
      <Registration>
        <AdminModalContext>
        <ToggleDropdownMenuContext>
        <CorporateUser>
          <OnboardingUser>
            <AddCorporateProduct>
              {children}
            </AddCorporateProduct>
          </OnboardingUser>
        </CorporateUser>
        </ToggleDropdownMenuContext>

        </AdminModalContext>
      </Registration>
    </Authorize>
  );
}

export default Store;
