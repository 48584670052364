import React from "react";
import { headData } from "./data";
// import Pagination from './pagination'
import { TableDiv } from "./style";
import Row from "./tableRow";
// import Fuse from 'fuse.js'

export default function Table({ result }) {
  // function searchData(){
  //     const options = {
  //         // isCaseSensitive: false,
  //         // includeScore: false,
  //         // shouldSort: true,
  //         // includeMatches: false,
  //         // findAllMatches: false,
  //         // minMatchCharLength: 1,
  //         // location: 0,
  //         // threshold: 0.6,
  //         // distance: 100,
  //         // useExtendedSearch: false,
  //         // ignoreLocation: false,
  //         // ignoreFieldNorm: false,
  //         keys: [
  //           "title",
  //           "author.firstName"
  //         ]
  //       };

  //       const fuse = new Fuse(membersData, options);

  //       // Change the pattern
  //       const pattern = ''

  //       return fuse.search(pattern)
  // }

  return (
    <TableDiv>
      <table>
        <thead>
          {headData.map((head, i) => (
            <td key={i}>{head}</td>
          ))}
        </thead>
        <tbody>
          {result.map((data, i) => (
            <Row index={i} id={i} data={data} />
          ))}
        </tbody>
      </table>
    </TableDiv>
  );
}
