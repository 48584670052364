import styled from 'styled-components/macro'

export const TopSellersContainerPage = styled.div`
    min-width: 151.83px;
    width: 168px;
    height: 76.75px;
    box-sizing: border-box;
    border-radius: 2.50272px;
    display: flex;
    padding-left:0px;
    justify-content: space-around;
    background-color: ${({bg}) => bg || null} !important;
    border: 0.96px solid rgba(154, 159, 191, 0.25);
    box-sizing: border-box;
    border-radius: 3px;
    margin-right: 10px;
    margin-top: 15px;
`

export const TopsellerImageItem = styled.img`
    border-radius: 50%;
    /* border: 2px solid #C2E0FF; */
    width: 60px;
`

export const TotalDivBs = styled.div`
    background: rgba(227, 232, 240, 0.51);
    border-radius: 2.50272px;
    padding: 3px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;

    img{
        width: 14px;
    }
`

export const DetailsDivBs = styled.div`
    padding: 10px 4px 10px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
 
`
export const BsNameText = styled.p`
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 12.0109px;
    line-height: 14px;
    color: #061E48;
`
export const TLocationText = styled.p`
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 9.83967px;
    color: #929AA7;
`

export const TotalTxt = styled.p`
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 8px;
    line-height: 11px;
    color: #274B89;
`

export const TopSellersFilterDiv = styled.div`
    display: flex;
    align-items: center;
    padding-right:10px;
    /* margin-right: 15px; */
    /* align-self: flex-end; */

    p{
        font-style: normal;
        font-weight: 600;
        font-size: 11.0995px;
        line-height: 17px;
        color: #274B89;
        /* margin-right: 5px; */
    }

    select{
        height: 30px;
        border: 1px solid #BBC4D4;
        /* box-sizing: border-box; */
        border-radius: 3px;
        color: #274B89;
    }
`;