import React from "react";

import Colors from "GlobalStyles/appColors";
import { categoryData } from "./categoryData";
import { useAdminModalContext } from "context/AdminModalContext";
import {
  ItemsContainer,
  CategoryButtonContainer,
  CategoryButton,
  CbottomButton,
  CbottomButton2,
  HeightSpacer,
  CategoryButtonContainer2,
} from "./style";
import CategoryContent from "./category";
import CategoriesHead from "./head";
import AddCategory from "./addnewCategory";
function Categories() {
  const { showmodal, setShowModal } = useAdminModalContext();
  // const [myInput, setMyInput] = useState("");
  // const [result, setResult] = useState([]);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleOpenModal = () => {
    setShowModal(true);
  };
  //   useEffect(() => {
  //     const data = !myInput
  //       ? membersData
  //       : membersData.filter(
  //           (data) =>
  //             data.name.toLowerCase().includes(myInput.toLowerCase()) ||
  //             data.email.toLowerCase().includes(myInput.toLowerCase()) ||
  //             data.status.toLowerCase().includes(myInput.toLowerCase())
  //         );
  //     setResult([...data]);
  //   }, [myInput]);

  return (
    <div>
      <AddCategory show={showmodal} close={handleCloseModal} />
      <CategoriesHead />
      <CategoryButtonContainer>
        <CategoryButton
          color={Colors.primary}
          borderColor={Colors.lightGreen}
          onClick={handleOpenModal}
        >
          Add new category +
        </CategoryButton>
        <CategoryButton color={Colors.danger} borderColor={Colors.danger}>
          Delete category
        </CategoryButton>
      </CategoryButtonContainer>
      <ItemsContainer>
        {categoryData?.map((category, i) => (
          <CategoryContent
            key={i}
            image={category.icon}
            textColor={category.isActive ? Colors.primary : Colors.primary}
            backgroundColor={
              category.isActive ? Colors.lightGreen : Colors.white
            }
            text={category.text}
          />
        ))}
      </ItemsContainer>

      <CategoryButtonContainer2>
        <HeightSpacer h="20px" />
        <CategoryButtonContainer>
          <CbottomButton>Cancel</CbottomButton>
          <CbottomButton2>Finish</CbottomButton2>
        </CategoryButtonContainer>
      </CategoryButtonContainer2>
    </div>
  );
}

export default Categories;
