import InputField from "components/onboading/inputField";
import SocialButton from "components/onboading/socialButton";
import { Div } from "GlobalStyles/spotrrStyles/style";
import React, { useRef, useState } from "react";
import {
  ForgotPasswordText,
  FormContainer,
  LoginButton,
  LoginForm,
  LoginText,
  UseSocials,
} from "./style";
import facebook from "assets/svg/facebook.svg";
import google from "assets/svg/google.svg";
import asyncFetch from "utilities/asyncFetch";
import { LOGINUSER } from "routes/backend";
// import { DASHBOARD } from 'routes/routes'
import { useCorporateUser } from "context/userDetails";
import Toast from "components/UI/Toast";
import { useAuthContext } from "context/AuthContext";
import { DASHBOARD } from "routes/routes";
import Modal from "components/UI/Modal";

export default function Form({ history }) {
  const [login, setLogin] = useState({});
  const { corporateUser } = useCorporateUser();
  const { user, setUser } = useAuthContext();
  const loginRef = useRef();
  const errRef = useRef("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [errMessage, setErrMessage] = useState("");
  const [showModal, setShowModal] = useState(false);

  function handleChange(e) {
    setLogin({
      ...login,
      [e.target.name]: e.target.value,
    });
  }

  function handleSubmit(e) {
    e.preventDefault();
    const email = !!login.identifier;
    const password = !!login.password;
    loginRef.current.textContent = "Loading...";
    setIsDisabled(true);
    const _ = undefined;
    if (!email || !password) {
      const err = "please fill in all fields";
      loginRef.current.textContent = "Login";
      setIsDisabled(false);
      setErrMessage(err);
      setShowModal(true);
      // setError(err)
      return;
    }

    asyncFetch("POST", _, login, LOGINUSER)
      .then((res) => {
        loginRef.current.textContent = "Login";
        setIsDisabled(false);
        console.log(login);
        console.log(res);
        if (res.status === true) {
          //continue login
          const { token, refreshToken } = res.data;
          const err = "Login Successful";
          setIsDisabled(false);
          setErrMessage(err);
          setShowModal(true);
          setUser({
            ...user,
            isAuth: true,
            token,
            refreshToken,
          });
          window.location.href = DASHBOARD;
        } else if (res.status === false) {
          const err = "email or password is/are incorrect";
          setIsDisabled(false);
          setErrMessage(err);
          setShowModal(true);
          // setError(err)
        }
      })
      .catch((err) => {
        const errs = "Network Error";
        setIsDisabled(false);
        setErrMessage(errs);
        setShowModal(true);
      });
  }

  const [show, setShow] = useState(false);

  function closUp() {
    console.log("got here working");
    setShow(!show);
  }
  return (
    <FormContainer>
      <Modal show={show} modalClosed={closUp}>
        <h1>Testing Modal</h1>
      </Modal>
      <Toast
        icon="error"
        description={errMessage}
        showModal={showModal}
        setShowModal={setShowModal}
      />
      <LoginText onClick={() => setShow(true)}>Login to your account</LoginText>
      <LoginForm>
        {console.log(corporateUser)}
        {console.log("loginUser", user)}
        <InputField
          value={login.identifier || ""}
          handleFormChange={handleChange}
          placeholder="Email address"
          name="identifier"
          displayEye={"false"}
          type="email"
          required="true"
        />
        <InputField
          value={login.password || ""}
          handleFormChange={handleChange}
          placeholder="Enter your password"
          name="password"
          type="password"
          required="true"
        />
        <Div display="flex" justify="flex-end">
          <ForgotPasswordText>Forgot Password</ForgotPasswordText>
        </Div>
        <UseSocials>
          <p>Or use</p>
          <Div display="flex" margin="40px 0" width="100%" justify="center">
            <SocialButton icon={facebook} />
            <SocialButton icon={google} />
          </Div>
        </UseSocials>
        <small
          style={{ color: "red", textAlign: "center" }}
          ref={errRef}
        ></small>
        <LoginButton
          disabled={isDisabled}
          ref={loginRef}
          onClick={handleSubmit}
        >
          Login
        </LoginButton>
      </LoginForm>
    </FormContainer>
  );
}
