import React from "react";

function AdsPage() {
  return (
    <div>
      <p>Ads Page</p>
    </div>
  );
}

export default AdsPage;
