import React, { useState, useEffect } from "react";
// import TopSellers from 'components/dashboard/main/topSellers'
// import BestRating from 'components/dashboard/main/bestRating'
// import GoogleMaps from 'components/dashboard/main/GoogleMap'
// import MapBox from 'components/dashboard/main/GoogleMap/mapBox'
// import Map from 'components/dashboard/main/GoogleMap/map'
// import { usePosition } from 'Hooks/usePosition'
import Sidebar from "components/dashboard/main/Sidebar";
import Menu from "components/dashboard/main/menu";
import { DashboardBody, DashboardContainer } from "./style";
import DashboardHeader from "./dashboardHeader/dashboard";
// import DashboardHome from './home/DashboardHome'
import { Switch } from "react-router-dom";
import { dashboardRoutes } from "./routes";
import MobileMenu from "components/dashboard/main/menu/mobileMenu";
import AddProduct from "../addProduct";
import ProtectedRoute from "utilities/ProtectedRoute";
import { useAuthContext } from "context/AuthContext";
import CorporateMenu from "context/corporateMenu";
import axios from "axios";
import { GETPROFILEDATA } from "routes/backend";
import { useHistory } from "react-router-dom";
import ToggleDropdwnContextProvider from "context/DropownMenuContext";

export default function Dashboard() {
  const [open, setOpen] = useState(false);
  const { user, setUser } = useAuthContext();
  const history = useHistory();
  const { data, token } = user;

  if (!user.isAuth) history.push("/");
  useEffect(() => {
    axios
      .get(GETPROFILEDATA, {
        headers: {
          Authorization: `bearer ${token}`,
        },
      })
      .then((res) => {
        const { data } = res.data;
        setUser((prev) => {
          return {
            ...prev,
            data: {
              ...prev.data,
              ...data,
            },
          };
        });
      }, [])
      .catch((err) => console.log(err));
  }, [setUser, token]);
  return (
    <DashboardContainer>
      <MobileMenu open={open} setOpen={setOpen} />
      <CorporateMenu>
        <Menu />
        <Sidebar open={open} setOpen={setOpen} />
      </CorporateMenu>
      <AddProduct />
      <DashboardBody>
        <ToggleDropdwnContextProvider>
          <DashboardHeader company={data?.brandName} />
        </ToggleDropdwnContextProvider>
        <Switch>
          {dashboardRoutes.map((route, i) => (
            <ProtectedRoute
              key={i}
              exact={route.exact}
              path={route.path}
              component={route.component}
            />
          ))}
        </Switch>
      </DashboardBody>
      {/* <TopSellers />
            <BestRating /> */}
    </DashboardContainer>
  );
}
