import React from 'react'

function Loans() {
    return (
        <div>
         Loans   
        </div>
    )
}

export default Loans
